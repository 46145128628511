<template>
  <div>
    <ed-modal v-model="boolExibirCadastroOpcao" width="80%">
      <div slot="title">{{ formData.strNome }} :: Inserir Novo Campo</div>

      <div class="" slot="content">
        <v-tabs v-model="strTab" centered icons-and-text>
          <v-tab href="#tab-cadastro">
            Informações Gerais
            <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
          </v-tab>
          <v-tab
            href="#tab-opcoes"
            v-if="[306, 328].indexOf(formDataCampo.intTipoCampoId) != -1"
          >
            Gerenciar Opções
            <i :class="$utilidade.getIcone('lista_opcao') + ' fa-2x'"></i>
          </v-tab>
        </v-tabs>

        <ed-form
          :formData="formDataCampo"
          :handlerSave="salvar"
          disableFixedSave
          savePosition=""
        >
          <cadastro
            :formData="formDataCampo"
            :formulario="formData"
            v-show="strTab == 'tab-cadastro'"
            :key="'cadastro-' + $root.$session.versao"
          />

          <opcao
            :formData="formDataCampo"
            v-show="strTab == 'tab-opcoes'"
            :key="'opcoes-' + $root.$session.versao"
          />
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      @edit="onEdit"
      :config="{ registerName: 'Inserir novo campo' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import Cadastro from "./campos.cadastro.vue";
import Opcao from "./campos.opcao.vue";

import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputSwitch,
  EdInputTipo,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputSwitch,
    EdInputTipo,
    Cadastro,
    Opcao,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroOpcao: false,
      strTab: "tab-cadastro",
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Categoria",
          sortable: true,
          value: "strCategoria",
        },
        {
          text: "Tipo de Campo",
          sortable: true,
          value: "intTipoCampoId",
        },
        {
          text: "Classificação por Sexo",
          sortable: true,
          value: "intTipoSexoId",
        },
        {
          text: "Obrigatório",
          sortable: true,
          value: "boolObrigatorio",
        },
        {
          text: "Ordem",
          sortable: true,
          value: "intOrdem",
        },
      ],
      arrayRows: [],
      formDataCampo: {},
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (!this.formData.campos) {
        this.formData.campos = [];
      }

      let arrayRows = [];
      this.formData.campos.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            strNome: objModel.strNome,
            strCategoria: objModel.categoria ? objModel.categoria.strNome : "---",
            intTipoSexoId: objModel.tipo_sexo ? objModel.tipo_sexo.strNome : "Não",
            intTipoCampoId: objModel.tipo_campo.strNome,
            boolObrigatorio: this.$utilidade.parseBoolean(objModel.boolObrigatorio),
            intOrdem: objModel.intOrdem,
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;

      this.formDataCampo = {
        intId: 0,
        strNome: null,
        boolAtivo: 1,
        intTipoCampoId: null,
        intFormularioCategoriaId: null,
        tipo_campo: null,
        opcoes: [],
        intOrdem: (this.formData.campos.length + 1) * 10,
      };
    },

    onEdit(data) {
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;

      this.formDataCampo = Object.assign(data, {});
      this.$forceUpdate();
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let intIndex = self.formData.campos.findIndex((c) => c.intId == intId);

        if (intIndex != -1) {
          self.formData.campos.splice(intIndex, 1);
        }
      });

      this.$forceUpdate();
      this.initialize();
    },

    salvar() {
      let intIndex = this.formData.campos.findIndex(
        (c) => c.intId == this.formDataCampo.intId
      );

      if (intIndex != -1) {
        this.formData.campos[intIndex] = Object.assign(this.formDataCampo, {});
      } else if (intIndex == -1) {
        this.formData.campos.push({
          intId: this.intRegistroInserido,
          intFormularioId: this.formData.intId,
          strNome: this.formDataCampo.strNome,
          intTipoCampoId: this.formDataCampo.intTipoCampoId,
          strCategoria: this.formDataCampo.strCategoria,
          strClasse: this.formDataCampo.strClasse,
          tipo_campo: this.formDataCampo.tipo_campo,
          opcoes: this.formDataCampo.opcoes,
          intOrdem: this.formDataCampo.intOrdem,
          boolAtivo: this.formDataCampo.boolAtivo,
        });
      }

      this.initialize();
      this.$forceUpdate();
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;
    },
  },
};
</script>
