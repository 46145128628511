<template>
  <div>
    <ed-modal v-model="boolExibirCadastro" width="50%">
      <div slot="title">
        {{ formData.strNome }} ::
        {{ formDataCategoria.intId > 0 ? "Editar" : "Inserir" }} Nova Categoria
      </div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataCategoria"
          :handlerSave="salvar"
          disableFixedSave
          savePosition=""
        >
          <div class="row">
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9"
              v-model="formDataCategoria.strNome"
              name="strNome"
              label="Nome da Categoria"
              rules="required"
            />

            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
              v-model="formDataCategoria.intOrdem"
              name="intOrdem"
              label="Ordem de Exibição"
              rules="required"
              type="number"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataCategoria.boolAtivo"
              name="boolAtivo"
              label="Categoria Ativo"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      @edit="onEdit"
      :config="{ registerName: 'Inserir nova categoria' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastro: false,
      strTab: "tab-cadastro",
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Ordem",
          sortable: true,
          value: "intOrdem",
        },
        {
          text: "Ativo",
          sortable: true,
          value: "boolAtivo",
        },
      ],
      arrayRows: [],
      formDataCategoria: {},
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    initialize() {
      if (!this.formData.categorias) {
        this.formData.categorias = [];
      }

      let arrayRows = [];
      this.formData.categorias.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            strNome:
              '<span style="color:' +
              (objModel.intId < 0 ? "orange" : "") +
              '">' +
              objModel.strNome +
              "</span>",
            intOrdem: objModel.intOrdem,
            boolAtivo: this.$utilidade.parseBoolean(objModel.boolAtivo),
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastro = !this.boolExibirCadastro;

      this.formDataCategoria = {
        intId: 0,
        strNome: null,
        boolAtivo: 1,
        intOrdem: this.formData.categorias.length + 1,
      };
    },

    onEdit(data) {
      this.boolExibirCadastro = !this.boolExibirCadastro;

      this.formDataCategoria = Object.assign(data, {});
      this.$forceUpdate();
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let intIndex = self.formData.categorias.findIndex((c) => c.intId == intId);

        if (intIndex != -1) {
          self.formData.categorias.splice(intIndex, 1);
        }
      });

      this.$forceUpdate();
      this.initialize();
    },

    salvar() {
      let intIndex = this.formData.categorias.findIndex(
        (c) => c.intId == this.formDataCategoria.intId
      );

      if (intIndex != -1) {
        this.formData.categorias[intIndex] = Object.assign(this.formDataCategoria, {});
      } else if (intIndex == -1) {
        this.formData.categorias.push({
          intId: (this.formData.categorias.length + 1) * -1,
          intFormularioId: this.formData.intId,
          strNome: this.formDataCategoria.strNome,
          intOrdem: this.formDataCategoria.intOrdem,
          boolAtivo: this.formDataCategoria.boolAtivo,
        });
      }

      this.initialize();
      this.$forceUpdate();
      this.boolExibirCadastro = !this.boolExibirCadastro;
    },
  },
};
</script>
