<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-8 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
          v-model="formData.strNome"
          name="strNome"
          label="Nome do Formulário"
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
          v-model="formData.intOrdem"
          name="intOrdem"
          label="Ordem de Exibição"
          rules="required"
        />

        <ed-input-switch
          class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
          v-model="formData.boolAtivo"
          name="boolAtivo"
          label="Formulário Ativo"
        />

      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputSelect,
  EdInputTipo,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdInputText,
    EdInputSelect,
    EdInputTipo,
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
