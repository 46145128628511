<template>
  <div class="mb-3">
    <ed-modal v-model="boolExibirCadastroModelo" width="80%">
      <div slot="title">{{ formData.strNome }} :: Inserir Novo Modelo</div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataModelo"
          :handlerSave="salvar"
          disableFixedSave
          savePosition=""
        >
          <div class="row">
            <ed-input-tipo
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataModelo.intTipoModeloId"
              name="intTipoModeloId"
              label="Tipo de Campo"
              tipo="307"
              rules="required"
              @selected="setTipoModelo"
            />
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      :config="{ registerName: 'Vincular novo modelo' }"
      disabledSearchRows
      disabledEditRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputTipo,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputTipo,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroModelo: false,
      arrayHeaders: [
        {
          text: "Aplicar em",
          sortable: true,
          value: "strNome",
        },
      ],
      arrayRows: [],
      formDataModelo: {
        intId: 0,
        intTipoModeloId: null,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.modelos) {
        this.formData.modelos = [];
      }

      let arrayRows = [];
      this.formData.modelos.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            strNome: objModel.tipo_modelo.strNome,
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroModelo = !this.boolExibirCadastroModelo;

      this.formDataModelo = {
        intId: 0,
        intTipoModeloId: null,
      };
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let indexCadastroEmail = self.formData.modelos.findIndex(
          (c) => c.intId == intId
        );

        if (indexCadastroEmail != -1) {
          self.formData.modelos.splice(indexCadastroEmail, 1);
        }
      });

      this.$forceUpdate();
      this.initialize();
    },

    setTipoModelo(objValue) {
      this.formDataModelo.tipo_modelo = objValue;
    },

    salvar() {
      let intIndexTipoModelo = this.formData.modelos.findIndex(
        (c) => c.intTipoModeloId == this.formDataModelo.intTipoModeloId
      );

      if (intIndexTipoModelo == -1) {
        this.intRegistroInserido--;

        this.formData.modelos.push({
          intId: this.intRegistroInserido,
          intTipoModeloId: this.formDataModelo.intTipoModeloId,
          intCampoPersonalizadoId: this.formData.intId,
          tipo_modelo: this.formDataModelo.tipo_modelo,
        });
      }

      this.initialize();
      this.boolExibirCadastroModelo = !this.boolExibirCadastroModelo;
    },
  },
};
</script>
