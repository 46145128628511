<template>
  <div class="mb-3">
    <ed-modal v-model="boolExibirCadastroOpcao" width="50%">
      <div slot="title">{{ formData.strNome }} :: Inserir Nova Opção</div>

      <div class="" slot="content">
        <ed-form
          :formData="formDataOpcao"
          :handlerSave="salvar"
          disableFixedSave
          savePosition=""
        >
          <div class="row">
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataOpcao.strNome"
              name="strNome"
              label="Nome da Opção"
              rules="required"
            />
            <ed-input-text
              class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              v-model="formDataOpcao.intOrdem"
              name="intOrdem"
              label="Ordem de Exibição"
              rules="required"
              type="number"
            />

            <ed-input-switch
              v-if="formData.intTipoCampoId == 328"
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataOpcao.boolPermitirOutro"
              name="boolPermitirOutro"
              label="Habilitar Campo Outros"
            />

            <ed-input-switch
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-model="formDataOpcao.boolAtivo"
              name="boolAtivo"
              label="Opção Ativo"
            />


            <div
              class="
                col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                text-left
                mb-4
              "
              style="background: #eeeeee"
              v-if="formDataOpcao.boolPermitirOutro"
            >
              <div class="pb-3"><b>Exemplo do campo com opção "outros"</b><br /></div>
              <img :src="'/img/campo/outro.png'" />
            </div>
          </div>
        </ed-form>
      </div>
    </ed-modal>

    <ed-table
    :key="$root.$session.versao"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :loading="loading"
      @register="onRegister"
      @delete="onDelete"
      @edit="onEdit"
      :config="{ registerName: 'Inserir nova opção' }"
      disabledSearchRows
    />
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputSwitch,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputSwitch,
  },
  provide() {
    return {};
  },
  mounted() {
    this.initialize();
  },
  created() {},
  data() {
    return {
      loading: false,
      boolExibirCadastroOpcao: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
         {
          text: "Permitir Outros",
          sortable: true,
          value: "boolPermitirOutro",
        },
        {
          text: "Ordem",
          sortable: true,
          value: "intOrdem",
        },
      ],
      arrayRows: [],
      formDataOpcao: {
        intId: 0,
        strNome: null,
      },
      intRegistroInserido: 0,
    };
  },
  watch: {
    formData: {
      handler: function (newVal) {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    initialize() {
      if (!this.formData.opcoes) {
        this.formData.opcoes = [];
      }

      let arrayRows = [];
      this.formData.opcoes.forEach((objModel) => {
        if (!objModel.strDataExclusao) {
          let item = {
            intId: objModel.intId,
            strNome: objModel.strNome,
            intOrdem: objModel.intOrdem,
            boolPermitirOutro:this.$utilidade.parseBoolean(objModel.boolPermitirOutro),
            _item: objModel,
          };

          arrayRows.push(item);
        }
      });

      this.arrayRows = arrayRows;
    },

    onRegister() {
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;

      this.formDataOpcao = {
        intId: 0,
        strNome: null,
        boolAtivo: 1,
        boolPermitirOutro: 0,
        intOrdem: this.formData.opcoes.length + 1,
      };
    },

    onEdit(data) {
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;

      this.formDataOpcao = Object.assign(data, {});
      this.$forceUpdate();
    },

    onDelete(data) {
      let self = this;
      data.forEach((intId) => {
        let intIndex = self.formData.opcoes.findIndex((c) => c.intId == intId);

        if (intIndex != -1) {
          self.formData.opcoes.splice(intIndex, 1);
        }
      });

      this.$forceUpdate();
      this.initialize();
    },

    salvar() {
      let intIndex = this.formData.opcoes.findIndex(
        (c) => c.intId == this.formDataOpcao.intId
      );

      if (intIndex != -1) {
        this.formData.opcoes[intIndex] = Object.assign(this.formDataOpcao, {});
      }
      if (intIndex == -1) {
        this.intRegistroInserido--;

        this.formData.opcoes.push({
          intId: this.intRegistroInserido,
          intFormularioCampoId: this.formData.intId,
          strNome: this.formDataOpcao.strNome,
          intOrdem: this.formDataOpcao.intOrdem,
          boolAtivo: this.formDataOpcao.boolAtivo,
        });
      }

      this.initialize();
      this.$forceUpdate();
      this.boolExibirCadastroOpcao = !this.boolExibirCadastroOpcao;
    },
  },
};
</script>
