<template>
  <div class="row">
    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5"
      v-model="formData.strNome"
      name="strNome"
      label="Nome do Campo"
      rules="required"
    />

    <ed-input-select
      class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-4"
      v-model="formData.intFormularioCategoriaId"
      name="intFormularioCategoriaId"
      label="Categoria"
      info="A categoria é utilizado para agrupar diversos campos em uma seção separada."
      rules="required"
      :items="formulario.categorias.filter((x) => x.intId > 0)"
      @selected="
        (x) => {
          formData.categoria = x;
        }
      "
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
      v-model="formData.intOrdem"
      name="intOrdem"
      label="Ordem de Exibição"
      rules="required"
    />

    <ed-input-tipo
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
      v-model="formData.intTipoSexoId"
      name="intTipoSexoId"
      label="Classificação por Sexo"
      tipo="74"
      clearable
      @selected="
        (x) => {
          formData.tipo_sexo = x;
        }
      "
    />

    <ed-input-tipo
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
      v-model="formData.intTipoCampoId"
      name="intTipoCampoId"
      label="Tipo de Campo"
      tipo="302"
      rules="required"
      @selected="
        (x) => {
          formData.tipo_campo = x;
        }
      "
    />

    <ed-input-text
      class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
      v-model="formData.strClasse"
      name="strClasse"
      label="Classe (Estilização)"
      info="Aqui você pode definiar alguma classe para aplicar uma estilizção no campo"
    />

    <ed-input-switch
      class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
      v-model="formData.boolAtivo"
      name="boolAtivo"
      label="Campo Ativo"
    />

    <ed-input-switch
      class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
      v-model="formData.boolObrigatorio"
      name="boolObrigatorio"
      label="Preenchimento Obrigatório"
    />

    <div
      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left mb-4"
      style="background: #eeeeee"
      v-if="formData.tipo_campo"
    >
      <div class="pb-3"><b>Exemplo do campo</b><br /></div>
      <img :src="'/img/campo/' + formData.tipo_campo.intId + '.png'" />
    </div>
  </div>
</template>

<script>
import {
  EdForm,
  EdTable,
  EdModal,
  EdButton,
  EdInputText,
  EdInputSelect,
  EdInputSwitch,
  EdInputTipo,
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    formulario: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
    formData: {
      type: [Object],
      required: true,
      default: function () {
        return {};
      },
    },
  },
  components: {
    EdForm,
    EdTable,
    EdModal,
    EdButton,
    EdInputText,
    EdInputSelect,
    EdInputSwitch,
    EdInputTipo,
  },
  provide() {
    return {};
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  computed: {},
  methods: {},
};
</script>
