<template>
  <div>
    <v-tabs v-model="strTab" centered icons-and-text>
      <v-tab href="#tab-cadastro">
        Cadastro Geral
        <i :class="$utilidade.getIcone('cadastroGeral') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-categoria">
        Categoria
        <i :class="$utilidade.getIcone('categoria') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-campos">
        Campos
        <i :class="$utilidade.getIcone('lista_opcao') + ' fa-2x'"></i>
      </v-tab>
      <v-tab href="#tab-modelo">
        Vincular Formulário
        <i :class="$utilidade.getIcone('arquivo') + ' fa-2x'"></i>
      </v-tab>
    </v-tabs>

    <ed-form
      :formData="formData"
      :handlerSave="salvar"
      v-if="!this.$root.$session.loadingContent"
    >
      <cadastro-geral
        :formData="formData"
        v-show="strTab == 'tab-cadastro'"
        :key="'cadastro-' + $root.$session.versao"
      />
      <cadastro-categoria
        :formData="formData"
        v-show="strTab == 'tab-categoria'"
        :key="'categoria-' + $root.$session.versao"
      />
      <cadastro-campos
        :formData="formData"
        v-show="strTab == 'tab-campos'"
        :key="'campos-' + $root.$session.versao"
      />
      <cadastro-modelo
        :formData="formData"
        v-show="strTab == 'tab-modelo'"
        :key="'modelo-' + $root.$session.versao"
      />
    </ed-form>
  </div>
</template>

<script>
import CadastroGeral from "./partials/geral.vue";
import CadastroCampos from "./partials/campos.vue";
import CadastroCategoria from "./partials/categoria.vue";
import CadastroModelo from "./partials/modelo.vue";

import { EdForm } from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFormularioId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  components: {
    EdForm,
    CadastroGeral,
    CadastroCampos,
    CadastroCategoria,
    CadastroModelo,
  },
  mounted() {
    this.getFormulario();
  },
  created() {},
  data() {
    return {
      strTab: "tab-cadastro",
      formData: {
        intId: 0,
        intOrdem: 1,
        boolAtivo: 1,
        campos: [],
        categorias: [],
      },
    };
  },
  watch: {
    intFormularioId() {
      this.getFormulario();
    },
  },
  computed: {},
  methods: {
    getFormulario() {
      if (!this.intFormularioId) return;

      this.$root.$dialog.loading(true);

      this.$root.$request
        .get("Cadastro/Formulario", {
          intId: this.intFormularioId,
        })
        .then((objResponse) => {
          this.$root.$dialog.loading(false);

          if (objResponse.status == 200) {
            this.formData = Object.assign(objResponse.result, {});
          }
        });
    },

    salvar() {
      this.$root.$request
        .post("Cadastro/Formulario", this.formData)
        .then((objResponse) => {
          if (objResponse.status == 200) {
            if (this.$route.name != "cadastro.formulario.edit") {
              this.$router.push({
                name: "cadastro.formulario.edit",
                params: {
                  intId: objResponse.result.intId,
                },
              });
            } else {
              this.getFormulario();
            }
          }
        });
    },
  },
};
</script>
